import React from 'react'
import AlternatingContent from './AlternatingContent'
import CtaCallout from './CtaCallout'
import HeroWithVideo from './HeroWithVideo'
import LeadershipTeaser from './LeadershipTeaser'
import TestimonialsSlider from './TestimonialsSlider'
import ManagementTabs from './ManagementTabs'
import PeopleGrid from './PeopleGrid'
import TestimonialGrid from './TestimonialGrid'
import AccoladeGrid from './AccoladeGrid'
import HeroHeadline from './HeroHeadline'
import ResourcesHeadingSearch from './ResourcesHeadingSearch'
import ContactForm from './ContactForm'
import FeaturedResources from './FeaturedResources'
import RecommendedVideos from './RecommendedVideos'
import UpcomingEvents from './UpcomingEvents'
import SubMenu from './SubMenu'
import QuickSearch from './QuickSearch'
import MediaContact from './MediaContact'
import BrowseSectors from '../BrowseSectors'
import RelatedContent from '../Resources/RelatedContent'
import HomepageHeroSlider from './HomepageHeroSlider'
import HeroSlider from './HeroSlider'
import GeneralAccordion from './GeneralAccordion'
import GeneralContent from './GeneralContent'
import OfficeList from './OfficeList'
import ResourcesByCategory from './ResourcesByCategory'
import LegalDisclaimer from './LegalDisclaimer'
import VideosGrid from './VideosGrid'

const FlexibleContent = ({ ...props }) => {
	return (
		<>
			{props.post.flexibleContent?.flexibleContent?.length &&
				props.post.flexibleContent?.flexibleContent?.map((block, index) => {
					const blockSiblings = {
						prevBlock:
							props.post.flexibleContent?.flexibleContent[index - 1]
								?.fieldGroupName,
						nextBlock:
							props.post.flexibleContent?.flexibleContent[index + 1]
								?.fieldGroupName,
					}
					const blockName = block.fieldGroupName
						? block.fieldGroupName.replace(
								'Page_Flexiblecontent_FlexibleContent_',
								''
						  )
						: null
					switch (blockName) {
						case 'AlternatingContent':
							return (
								<AlternatingContent
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'CtaCallout':
							return (
								<CtaCallout
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'HeroWithVideo':
							return (
								<HeroWithVideo
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'HeroHeadline':
							return (
								<HeroHeadline
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'ResourcesHeadingSearch':
							return (
								<ResourcesHeadingSearch
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'ContactForm':
							return (
								<ContactForm
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'LeadershipTeaser':
							return (
								<LeadershipTeaser
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'TestimonialsSlider':
							return (
								<TestimonialsSlider
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'ManagementTabs':
							return (
								<ManagementTabs
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'PeopleGrid':
							return (
								<PeopleGrid
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'TestimonialGrid':
							return (
								<TestimonialGrid
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'AccoladeGrid':
							return (
								<AccoladeGrid
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'FeaturedResources':
							return (
								<FeaturedResources
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'RecommendedVideos':
							return (
								<RecommendedVideos
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'UpcomingEvents':
							return (
								<UpcomingEvents
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'Submenu':
							return (
								<SubMenu
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'QuickSearch':
							return (
								<QuickSearch
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'MediaContacts':
							return (
								<MediaContact
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'BrowseSectors':
							return (
								<BrowseSectors
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'ResourcesCarousel':
							return (
								<RelatedContent
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'HomepageHeroSlider':
							return (
								<HomepageHeroSlider
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'HeroSlider':
							return (
								<HeroSlider
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'Accordion':
							return (
								<GeneralAccordion
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'GeneralContent':
							return (
								<GeneralContent
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
						case 'OfficeList':
							return (
								<OfficeList
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
								/>
							)
							case 'LegalDisclaimer':
								return (
									<LegalDisclaimer
										{...block}
										key={`flexible-content-${index}`}
										{...blockSiblings}
									/>
								)
						case 'ResourcesByCategory':
							return (
								<ResourcesByCategory
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
									/>
							)
						case 'VideosGrid':
							return (
								<VideosGrid
									{...block}
									key={`flexible-content-${index}`}
									{...blockSiblings}
									/>
							)
					}
				})}
		</>
	)
}

export default FlexibleContent
