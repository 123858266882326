import React from 'react'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import { mediaAreaImg } from './HeroMedia.module.scss'
import MediaModal from '../../MediaModal';
import {videoPlayButton} from '../../Resources/Detail/Video/Video.module.scss';
import Play from '../../Play';

// create the play button to pass to HOC
const VideoPlayButton = ({ image, onClick, ariaDescribedby }) => {
	return (
		<button
			onClick={onClick}
			type="button"
			aria-label={`Watch Video`}
			aria-describedby={ ariaDescribedby || null }
			className={videoPlayButton}
		>
			<GatsbyImage
				alt=""
				image={image}
			/>
			<Play size="large" bgColor="transparent" />
		</button>
	)
}

const HeroMedia = ({ videoLink, imgSrc, altText, variant, id }) => {
	let image = null;
	if (typeof imgSrc === 'object' && imgSrc !== null) {
		image = getImage(imgSrc)
	}
	const mediaEl = variantType => {
		switch (variantType) {
			case 'video':

				const VideoModal = MediaModal(VideoPlayButton, {
					title: altText,
					image: image,
					ariaDescribedby: id,
				})

				return (
					<div className={mediaAreaImg}>
						<VideoModal
							title={altText}
							modalId="video-modal"
							content={
								<div className="ratio ratio-16x9">
									<iframe
										src={videoLink}
										title={altText}
										allowFullScreen
									/>
								</div>
							}
						/>
					</div>
				)

			case 'image':
				return (
					<div className={mediaAreaImg}>
						<GatsbyImage alt={altText} image={imgSrc} />
					</div>
				)
		}
	}

	return <>{variant ? mediaEl(variant) : null}</>
}

export default HeroMedia
