import React from 'react'
import CallToAction from '../../CallToAction'
import { ctaCallout } from './CtaCallout.module.scss'

const CtaCallout = ({ ...props }) => {
	return (
		<div className={ctaCallout}>
			<CallToAction
				href={props.ctaLink.uri}
				buttonText={props.ctaText}
				headingText={props.headline}
			/>
		</div>
	)
}

export default CtaCallout
