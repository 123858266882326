import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
// We're using Gutenberg so we need the block styles
import '@wordpress/block-library/build-style/style.css'
import '@wordpress/block-library/build-style/theme.css'
import Layout from '../components/Layout'
import FlexibleContent from '../components/FlexibleContent'
import Seo from 'gatsby-plugin-wpgraphql-seo';

const PageTemplate = ({ data: { post } }) => {
	return (
		<Layout isFrontPage={post.isFrontPage}>
			<Seo post={post} />
			<FlexibleContent post={post} />

			{!!post.content && (
				<section itemProp="articleBody">{parse(post.content)}</section>
			)}
		</Layout>
	)
}

export default PageTemplate

export const pageQuery = graphql`
	fragment peopleData on WpPeople {
		id
		uri
		slug
		title
		content
		excerpt
		jobTitles {
			nodes {
				name
				id
			}
		}
		peopleInformation {
			email
			fax
			fieldGroupName
			firstName
			lastName
			linkedin
			mobile
			phone
			twitter
			proOffice {
				... on WpOffice {
					title
					slug
					officeInformation {
						proOfficeCity
						proOfficeState
						proOfficeStreet
						proOfficeZipcode
					}
				}
			}
			proService {
				... on WpService {
					slug
					title
				}
			}
		}
		featuredImage {
			node {
				localFile {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.87, width: 300)
					}
				}
			}
		}
		locale {
			id
			locale
		}
		translations {
			id
			locale
			href
		}
		contentType {
			node {
				name
			}
		}
	}
	fragment ResourcePost on WpPost {
		title
		uri
		id
		date(formatString: "MMMM DD, YYYY")
		resourceTypes {
			nodes {
				slug
				name
			}
		}
		categories {
			nodes {
				slug
				name
				uri
			}
		}
		featuredImage {
			node {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.59, width: 850)
					}
				}
			}
		}
		videoInformation {
			vimeoPlayer
			duration
		}
		eventInformation {
			startDate
			endDate
			time
		}
		resourceInformation {
			service {
				... on WpService {
					title
					uri
					serviceTypes {
						nodes {
							slug
							name
						}
					}
				}
			}
		}
	}
	query PageById(
		# these variables are passed in via createPage.pageContext in gatsby-node.js
		$id: String!
	) {
		# selecting the current post by id
		post: wpPage(id: { eq: $id }) {
			isFrontPage
			id
			content
			title
			date(formatString: "MMMM DD, YYYY")
			seo {
				...SeoFields
			}
			locale {
				locale
			}
			translations {
				locale
				href
			}
			flexibleContent {
				... on WpPage_Flexiblecontent {
					flexibleContent {
						__typename
						... on WpPage_Flexiblecontent_FlexibleContent_HeroWithVideo {
							fieldGroupName
							headline
							content
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											layout: CONSTRAINED
											aspectRatio: 1.59
											width: 1000
										)
									}
								}
							}
							videoLink
							ctaText
							ctaLink {
								__typename
								... on WpPage {
									uri
								}
							}
						}

						... on WpPage_Flexiblecontent_FlexibleContent_HeroHeadline {
							fieldGroupName
							headline
							includeContainer
							includePadding
							menu
						}

						... on WpPage_Flexiblecontent_FlexibleContent_ResourcesHeadingSearch {
							fieldGroupName
							headline
							includeContainer
							includePadding
						}

						... on WpPage_Flexiblecontent_FlexibleContent_ContactForm {
							fieldGroupName
							gravityForm
						}

						... on WpPage_Flexiblecontent_FlexibleContent_LeadershipTeaser {
							fieldGroupName
							headline
							content
							people {
								...peopleData
							}
							ctaText
							ctaLink {
								__typename
								... on WpPage {
									uri
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_AlternatingContent {
							fieldGroupName
							backgroundStyle
							layout
							headline
							content
							accoladesTeaser
							accolades {
								... on WpPeople {
									id
									title
									date
								}
							}
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											layout: CONSTRAINED
											aspectRatio: 1.4
											width: 650
										)
									}
								}
							}
							videoLink
							ctaText
							ctaLink {
								__typename
								... on WpPage {
									uri
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_OfficeList {
							fieldGroupName
							offices {
								... on WpOffice {
									id
									title
									officeInformation {
										proOfficeZipcode
										proOfficeStreet
										proOfficeState
										proOfficePhone
										proOfficePeopleLink
										proOfficeLng
										proOfficeLat
										proOfficeFax
										proOfficeImage {
											id
											localFile {
												childImageSharp {
													gatsbyImageData(
														layout: CONSTRAINED
														aspectRatio: 1
														width: 150
													)
												}
											}
										}
										proOfficeDirectionsLink
										proOfficeCity
										fieldGroupName
									}
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_TestimonialGrid {
							fieldGroupName
							headline
							includeSearch
							disclaimerContent
							disclaimerHeadline
							testimonial {
								... on WpTestimonial {
									id
									content
									testimonialInformation {
										author
										jobtitle
										size
										testimonialfull
										variant
										proService {
											... on WpService {
												slug
												title
											}
										}
									}
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_AccoladeGrid {
							fieldGroupName
							headline
							limit
							accoladeGroup {
								... on WpPost {
									id
									uri
									accoladeInformation {
										date
										accolade
										fieldGroupName
										accoladetitle
										source
									}
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_HeroSlider {
							fieldGroupName
							content
							headline
							search
							slides {
								fieldGroupName
								title
								label
								slideLink {
									... on WpPost {
										id
										uri
									}
									... on WpPage {
										id
										uri
									}
									... on WpService {
										id
										uri
										serviceTypes {
											nodes {
												slug
											}
										}
									}
								}
								slideImage {
									id
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(layout: CONSTRAINED, quality: 90)
										}
									}
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_TestimonialsSlider {
							fieldGroupName
							headline
							ctaText
							ctaLink {
								__typename
								... on WpPage {
									uri
								}
							}
							testimonialGroup {
								... on WpTestimonial {
									id
									content
									testimonialInformation {
										author
										jobtitle
										fieldGroupName
									}
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_CtaCallout {
							fieldGroupName
							headline
							ctaText
							ctaLink {
								__typename
								... on WpPage {
									uri
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_ManagementTabs {
							fieldGroupName
							teamLeaders {
								team {
									teamLabel
									teamLeader {
										...peopleData
									}
								}
							}
							nonLegalManagement {
								nonLegalMember {
									...peopleData
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_PeopleGrid {
							fieldGroupName
							showContactInformation
							sectionTitle
							people {
								...peopleData
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_FeaturedResources {
							fieldGroupName
							includeRecent
							featuredResources {
								...ResourcePost
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_RecommendedVideos {
							fieldGroupName
							heading
							videos {
								...ResourcePost
							}
							ctaButton {
								text
								href
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_LegalDisclaimer {
							disclaimer
							fieldGroupName
							headline
						}
						... on WpPage_Flexiblecontent_FlexibleContent_UpcomingEvents {
							fieldGroupName
							heading
							resources {
								...ResourcePost
							}
							ctaButton {
								text
								href
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_Submenu {
							fieldGroupName
							menu
						}
						... on WpPage_Flexiblecontent_FlexibleContent_QuickSearch {
							fieldGroupName
							headingText
							introCopy
							links {
								text
								href
							}
							ctaButton {
								text
								href
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_MediaContacts {
							newsletterCta {
								ctaText
								ctaLink {
									url
								}
								newsletterHeading
							}
							fieldGroupName
							contactPerson {
								accessibleContactPhone
								contactEmail
								contactFax
								contactNameTitle
								contactPhone
								contactType
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_BrowseSectors {
							fieldGroupName
							headingText
							type
						}
						... on WpPage_Flexiblecontent_FlexibleContent_ResourcesCarousel {
							fieldGroupName
							headingText
							description
							resources {
								... on WpPost {
									title
									uri
									id
									date(formatString: "MMMM DD, YYYY")
									resourceTypes {
										nodes {
											slug
											name
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												childImageSharp {
													gatsbyImageData(
														aspectRatio: 0.75
														layout: CONSTRAINED
														width: 485
													)
												}
											}
										}
									}
									resourceInformation {
										service {
											... on WpService {
												title
												uri
												serviceTypes {
													nodes {
														slug
														name
													}
												}
											}
										}
									}
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_HomepageHeroSlider {
							fieldGroupName
							slide {
								published
								title
								label {
									tag
									label
								}
								description
								descriptionLabel
								image {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												layout: FULL_WIDTH
												aspectRatio: 1.4
												width: 2500
											)
										}
									}
								}
								ctaButton1 {
									text
									href {
										... on WpPage {
											uri
										}
										... on WpService {
											id
											uri
											serviceTypes {
												nodes {
													slug
												}
											}
										}
									}
								}
								ctaButton2 {
									text
									href {
										... on WpPage {
											uri
										}
										... on WpService {
											id
											uri
											serviceTypes {
												nodes {
													slug
												}
											}
										}
									}
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_Accordion {
							fieldGroupName
							columnWidth
							accordion {
								accordionContent
								accordionTitle
								optionalCta {
									ctaLink
									ctaText
								}
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_GeneralContent {
							fieldGroupName
							columnWidth
							content
						}
						... on WpPage_Flexiblecontent_FlexibleContent_ResourcesByCategory {
							fieldGroupName
							category {
								slug
							}
						}
						... on WpPage_Flexiblecontent_FlexibleContent_VideosGrid {
							fieldGroupName
							excludePaddingBottom
							excludePaddingTop
							video {
								image {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												layout: CONSTRAINED,
												aspectRatio: 1.77,
												width: 1076
											)
										}
									}
								}
								vimeoPlayer
								title
								description
							}
						}
					}
				}
			}
		}
	}
`
