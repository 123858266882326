import React from 'react'
import clsx from "clsx";
import Link from '../../../Link'
import {useAccolades} from "../../../../hooks/use-accolades";
import {
  accoladesTeaserList,
  accoladesList,
  accoladeDate,
  accoladeTitle
} from "./AccoladesTeaserList.module.scss"

const AccoladesTeaserList = ({ ...props }) => {
  let accolades = props.list ?? useAccolades().slice(0,5)
  return (
    <div className={accoladesTeaserList}>
      <h3 className="label-small">Featured Accolades</h3>

      <ul className={clsx(accoladesList, 'list-unstyled')}>
        {accolades.map((item) => {
          const accolade = item.hasOwnProperty('accolade')
            ? item.accolade
            : item
          const info = accolade.accoladeInformation
          
          if (!info.accoladetitle) return
          return (
            <li key={accolade.id}>
              <Link to={accolade.uri}>
              <span className={accoladeDate}>
                {info.date}
              </span>
              <span className={accoladeTitle + " h2"}>
                {info.accoladetitle}
              </span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default AccoladesTeaserList