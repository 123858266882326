import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PeopleCard from '../../PeopleList/PeopleCard'

const PeopleGrid = ({ ...props }) => {
	const { sectionTitle, people, showContactInformation } = props

	return (
		<>
			<Container className="mt-4" fluid="lg">
				{sectionTitle && <h2 className="mb-4">{sectionTitle}</h2>}
				<Row>
					{people?.map((person, i) => {
						return (
							// have to add key to Col or get react error regarding lists and keys
							<Col xs={6} sm={4} md={4} xl={3} key={i} className="d-flex align-items-stretch">
								<PeopleCard
									people={person}
									hideContact={!showContactInformation}
								/>
							</Col>
						)
					})}
				</Row>
			</Container>
		</>
	)
}

export default PeopleGrid
