import React from 'react'
import Container from 'react-bootstrap/Container'
import Slider from './Slider'
import { testimonialsSlider } from './TestimonialsSlider.module.scss'

const TestimonialsSlider = ({ ...props }) => {
	return (
		<div className={testimonialsSlider}>
			<Container>
				<Slider
					headline={props.headline}
					ctaText={props.ctaText}
					testimonialsList={props.testimonialGroup}
					ctaLink={props.ctaLink.uri}
				/>
			</Container>
		</div>
	)
}

export default TestimonialsSlider
