import React from 'react'
import {GatsbyImage, StaticImage, getImage} from "gatsby-plugin-image";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Link from '../../../Link'
import { peopleHeadshot, nameTitleWrapper } from '../ManagementTabs.module.scss'

const ManagementTabRow = ({ ...props }) => {
	const col = props.teamLabel ? {xs: 12, sm: 7, lg: 5} : {xs:12}
	return (
		<Row className="border-bottom border-1 border-gray align-items-center py-4">
			{props.teamLabel ? (
				<Col xs={12} sm={5} lg={7}>
					<div className="title--lg">{props.teamLabel}</div>
				</Col>
			) : null}
			<Col {...col}>
				{props.leaders.length
					? props.leaders?.map(people => {
						if (!people) return null;
						const jobTitlesArray = people?.jobTitles?.nodes
						const headshot = people?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
						const image = headshot ? getImage(headshot) : null
						const name = people.title
						return (
								<div
									key={people.id}
									className="d-inline-flex d-sm-flex flex-wrap py-2"
								>
										<div className={peopleHeadshot}>
											<Link to={people.uri}>
												{image ? (
													<>
														<GatsbyImage
															className="img-fluid rounded"
															image={image}
															alt={people.title}
														/>
													</>
												) : (
													<>
														<StaticImage
															className="img-fluid rounded"
															src="../../../../images/logo-placeholder.png"
															alt={people?.title}
															aspectRatio={1.14}
														/>
													</>
												)}
											</Link>
										</div>

									<div className={nameTitleWrapper}>
										<Link to={people.uri}>
											{name ? (
												<div className={['title--md', name].join(' ')}>
													{name}
												</div>
											) : null}

											{jobTitlesArray ? (
												<div className="d-inline-flex label-small text-uppercase">
													<div className="d-block d-sm-flex flex-wrap">
														{jobTitlesArray.map((job, i, arr) => (
															<div key={`job-${i}`}>
																	<span>{job.name}</span>
																	{arr.length > i + 1 ? (
																		<span className="mx-1">&</span>
																	) : null}
																</div>
														))}
													</div>
												</div>
											) : null}
										</Link>
									</div>
								</div>
							)
					  })
					: null}
			</Col>
		</Row>
	)
}

export default ManagementTabRow
