import React from 'react'
import {Col, Row, Container} from "react-bootstrap";
import StyledCard from "../../Common/StyledCard";
import {useResourcesByService} from "../../../hooks/use-resources-by-service";
import {listResources} from "../../../list/resources/ListResources.module.scss";

const ResourcesByCategory = ({...props}) => {
  const resources = useResourcesByService(props?.category?.slug, true)

  return (
		<section className={listResources}>
			<Container>
				{resources && (
					<Row>
						{resources.map(({ resource }, index) => {
							return (
								<Col sm={4} key={index}>
									<StyledCard
										variation="CardC"
										service={resource?.resourceInformation?.service}
                    label={resource.resourceTypes?.nodes[0]?.name}
                    labelId={resource.resourceTypes?.nodes[0]?.slug}
										href={resource.uri}
										title={resource.title}
										headingSize="title--sm"
										image={
                      resource.featuredImage?.node?.localFile?.childImageSharp
												?.gatsbyImageData
										}
										eventDetails={null}
										imageSize="medium"
									/>
								</Col>
							)
						})}
					</Row>
				)}
			</Container>
		</section>
	)
}

export default ResourcesByCategory