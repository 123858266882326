import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { v4 as uuidv4 } from 'uuid'
import HeroHeadline from '../HeroHeadline'
import HeroCta from '../HeroCta'
import HeroMedia from '../HeroMedia'
import getVimeoLink from "../../../helpers/getVimeoLink"
import {
	headlineWrapper,
	heroWithVideo,
	content,
	video,
	contentWrapper,
	textOnlyCta,
} from './HeroWithVideo.module.scss'

const HeroWithVideo = ({ ...props }) => {
	const vimeoLink = getVimeoLink(props?.videoLink)
	const altText = props.headline.replace(/(<([^>]+)>)/ig,'')
	const id = uuidv4()

	return (
		<div className={heroWithVideo}>
			<Container>
				<Row>
					{props.image ? (
						<Col md={{ span: 6, order: 1 }} lg={8}>
							<div className={video}>
								<HeroMedia
									videoLink={vimeoLink}
									variant="video"
									imgSrc={
										props?.image?.localFile?.childImageSharp?.gatsbyImageData
									}
									altText={props.headline ? altText : ''}
									id={`headline-` + id}
								/>
							</div>
						</Col>
					) : null}
					<Col
						className="d-flex align-items-center"
						md={{ span: 6, order: 0 }}
						lg={4}
					>
						<div className={contentWrapper}>
							{props.headline ? (
								<div className={headlineWrapper}>
									<HeroHeadline
										headline={props.headline}
										includeContainer={false}
										headingSize="h2"
										includePadding={false}
										id={`headline-` + id}
									/>
								</div>
							) : null}
							{props.content ? (
								<div
									className={`editor-section ` + content}
									dangerouslySetInnerHTML={{ __html: props.content }}
								/>
							) : null}
							
							{props.ctaLink ? (
								<HeroCta
										variant="icon"
										icon="down"
										href={props.ctaLink?.uri}
										text={props.ctaText}
									/>
								)
								: props.ctaText  ? (
									<HeroCta
										variant="text-only"
										icon="down"
										text={props.ctaText}
									/>
								)
								: null
							}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default HeroWithVideo
