import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import HeroHeadline from '../HeroHeadline'
import HeroCta from '../HeroCta'
import HeroMedia from '../HeroMedia'
import {
	alternatingContent,
	accoladesTeaser,
	bg1,
	bg2,
	bgCommunityService,
	content,
	cta,
	headlineWrapper,
	media,
	contentWrapper,
	textOnLeft,
	noPaddingTop
} from './AlternatingContent.module.scss'
import AccoladesTeaserList from './AccoladesTeaserList'

const AlternatingContent = ({ ...props }) => {
	let containerClass = [alternatingContent]
	let contentWrapperClass = [contentWrapper]

	if (props.accoladesTeaser && props.accoladesTeaser) {
		containerClass.push(accoladesTeaser)
	}

	if (props.layout === 'text_on_left') {
		contentWrapperClass.push(textOnLeft)
	}
	
	if (props.prevBlock?.endsWith('HeroHeadline')){
		containerClass.push(noPaddingTop)
	}

	// load in the background styles (textural elements)
	switch (props.backgroundStyle) {
		case 'bg-1':
			containerClass.push(bg1)
			break
		case 'bg-2':
			containerClass.push(bg2)
			break
		case 'bg-community-service':
			containerClass.push(bgCommunityService)
			break
	}

	// default is text on left
	let mediaOrder = { order: 3 }
	let contentOrder = { order: 1 }
	let mediaOrderXs = null
	let contentOrderXs = null
	let mediaCol = {
		md: { span: 5, offset: 1 },
		lg: { span: 6, offset: 1 },
	}
	let textCol = {
		md: { span: 5, offset: 1 },
		lg: { span: 4, offset: 1 },
	}

	// column values for text on right
	if (props.layout === 'text_on_right') {
		mediaOrder = { order: 1 }
		contentOrder = { order: 3 }

		mediaCol = {
			md: { span: 5 },
			lg: { span: 6 },
		}

		textCol = {
			md: { span: 5, offset: 1 },
			lg: { span: 4, offset: 1 },
		}
	}

	if (props.accoladesTeaser) {
		mediaOrderXs = { order: 2, span: 12 }
		contentOrderXs = { order: 1, span: 12 }
	}

	return (
		<div className={containerClass.join(' ')}>
			<Container>
				<Row>
					<Col xs={mediaOrderXs} sm={mediaOrder} md={mediaCol.md} lg={mediaCol.lg}>
						<div className={media}>
							{props.videoLink ? (
								<HeroMedia
									videoLink={props.videoLink}
									variant="video"
									altText={props.headline}
									imgSrc={
										props?.image?.localFile?.childImageSharp?.gatsbyImageData
									}
								/>
							) : props.accoladesTeaser ? (
								<AccoladesTeaserList list={props.accolades} />
							) : (
								<HeroMedia
									variant="image"
									altText={props.headline}
									imgSrc={
										props?.image?.localFile?.childImageSharp?.gatsbyImageData
									}
								/>
							)}
						</div>
					</Col>
					<Col xs={contentOrderXs} sm={contentOrder} md={textCol.md} lg={textCol.lg}>
						<div className={contentWrapperClass.join(' ')}>
							{props.headline ? (
								<div className={headlineWrapper}>
									<HeroHeadline
										headline={props.headline}
										includeContainer={false}
										headingSize="h2"
										includePadding={false}
									/>
								</div>
							) : null}
							{props.content ? (
								<div
									className={`editor-section ` + content}
									dangerouslySetInnerHTML={{ __html: props.content }}
								/>
							) : null}
							{props?.ctaText && props?.ctaLink?.uri ? (
								<div className={cta}>
									<HeroCta
									variant="btn-link-primary"
									href={props?.ctaLink?.uri}
									text={props?.ctaText}
									/>
								</div>
							) : null}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default AlternatingContent
