import { useStaticQuery, graphql } from 'gatsby'

export const useUpcomingEvents = () => {
    const {allWpPost} = useStaticQuery(
      graphql`
          query AllUpcomingEvents {
              allWpPost(
                  sort: { fields: [eventInformation___startDate], order: ASC }
                  filter: {resourceTypes: {nodes: {elemMatch: {slug: {eq: "events"}}}}}
              ) {
                  edges {
                      event: node {
                          id
                          uri
                          date(formatString: "MMMM DD, YYYY")
                          title
                          categories {
                              nodes {
                                  uri
                                  name
                              }
                          }
                          resourceTypes {
                              nodes {
                                  slug
                                  name
                              }
                          }
                          featuredImage {
                              node {
                                  altText
                                  localFile {
                                      childImageSharp {
                                          gatsbyImageData(
                                              layout: CONSTRAINED,
                                              aspectRatio: 1.59,
                                              width: 550
                                          )
                                      }
                                  }
                              }
                          }
                          eventInformation {
                              startDate
                              endDate
                              time
                          }
                          resourceInformation {
                              people {
                                  ... on WpPeople {
                                      id
                                  }
                              }
                              service {
                                  ... on WpService {
                                      title
                                      uri
                                      serviceTypes {
                                          nodes {
                                              slug
                                              name
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      `
    )
    return filterByDate(allWpPost?.edges)
}

const filterByDate = (events) => {
    let now = new Date();
    now = `${now.getFullYear()}-${("0" + (now.getMonth() + 1)).slice(-2)}-${("0" + now.getDate()).slice(-2)}`
    return events?.filter(({event}) => event?.eventInformation?.startDate > now).slice(0,3)
}