import { useStaticQuery, graphql } from 'gatsby'

export const useAccolades = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
        query AllAccolades {
            allWpPost(
                sort: { fields: [date], order: DESC }
                filter: {accoladeInformation: {accolade: {eq: "yes"}}}
            ) {
                edges {
                    accolade: node {
                        id
                        uri
                        accoladeInformation {
                            date
                            accolade
                            fieldGroupName
                            accoladetitle
                            source
                        }
                    }
                }
            }
        }
    `
  )
  return allWpPost.edges
}