import React, {useState} from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import HeroHeadline from '../HeroHeadline'
import HeroCta from '../HeroCta'
import HeroSliderVertical from '../HeroSliderVertical'
import {
	slider,
	images,
	imagesInner,
	imagesInnerRight,
	content,
	leadershipTeaser,
	cta,
	headlineWrapper,
	contentArea,
	isPaused,
	pauseButton,
} from './LeadershipTeaser.module.scss'
import clsx from "clsx";
import BtnLink from "../../Common/StyledButton/BtnLink";

const LeadershipTeaser = ({ ...props }) => {
	const [paused, setPaused] = useState(false);
	const peopleLeft = props?.people?.slice(0, Math.ceil(props.people.length / 2))
	const peopleRight = props?.people?.slice(
		Math.ceil(props.people.length / 2),
		props.people.length
	)

	const imagesLeft = peopleLeft?.map(people => {
		return {image: people?.featuredImage?.node?.localFile?.childImageSharp
				?.gatsbyImageData, alt: people.title}
	})

	const imagesRight = peopleRight?.map(people => {
		return {image: people?.featuredImage?.node?.localFile?.childImageSharp
				?.gatsbyImageData, alt: people.title}
	})

	const handlePause = e => {
		setPaused(!paused)
	}

	return (
		<div className={leadershipTeaser}>
			<Container fluid="lg">
				<Row>
					<Col sm={6} style={{position: 'relative'}}>
						<div className={pauseButton}>
							<BtnLink variant="white" onClick={handlePause} cta={paused ? 'Play' : 'Pause'} aria-label={paused ? 'Play Animation' : 'Pause Animation'} useButton={true} />
						</div>
						{imagesLeft || imagesRight ? (
							<div className={[slider, 'd-none d-sm-block'].join(' ')}>
								<div className={images}>
									<div className={clsx(imagesInner, paused ? isPaused : '')}>
										<HeroSliderVertical
											images={imagesLeft}
											link={props?.ctaLink?.uri}
										/>
									</div>
									<div className={clsx(imagesInner, imagesInnerRight, paused ? isPaused : '')}>
										<HeroSliderVertical
											second={true}
											images={imagesRight}
											link={props?.ctaLink?.uri}
										/>
									</div>
								</div>
							</div>
						) : null}
					</Col>
					<Col xs={12} sm={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}>
						<div className={contentArea}>
							{props.headline ? (
								<div className={headlineWrapper}>
									<HeroHeadline
										headline={props.headline}
										includeContainer={false}
										headingSize="h2"
										includePadding={true}
									/>
								</div>
							) : null}
							{props.content ? (
								<div
									className={`editor-section ` + content}
									dangerouslySetInnerHTML={{ __html: props.content }}
								/>
							) : null}
							{props?.ctaText && props.ctaLink?.uri && (
								<div className={cta}>
									<HeroCta
										variant="btn-link"
										href={props?.ctaLink?.uri}
										text={props?.ctaText}
									/>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default LeadershipTeaser
