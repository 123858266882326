// extracted by mini-css-extract-plugin
export var leadershipTeaser = "LeadershipTeaser-module--leadershipTeaser--2v4vS";
export var headlineWrapper = "LeadershipTeaser-module--headlineWrapper--GysIJ";
export var slider = "LeadershipTeaser-module--slider--1K7N1";
export var images = "LeadershipTeaser-module--images--1WpLb";
export var imagesInner = "LeadershipTeaser-module--images-inner--1ed0r";
export var c = "LeadershipTeaser-module--c--1PW3y";
export var isPaused = "LeadershipTeaser-module--is-paused--1PZGg";
export var imagesInnerRight = "LeadershipTeaser-module--images-inner-right--1T66-";
export var pauseButton = "LeadershipTeaser-module--pauseButton--fKajx";
export var contentArea = "LeadershipTeaser-module--contentArea--hcMGf";
export var content = "LeadershipTeaser-module--content--2SHVF";
export var cta = "LeadershipTeaser-module--cta--28y9C";