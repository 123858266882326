import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import StyledCard from '../../Common/StyledCard'
import EventDate from '../../../helpers/EventDate'
import {
	featuredResources,
	recentPostsWrapper,
	featuredResourcesSmall,
} from './FeaturedResources.module.scss'

const FeaturedResources = ({ ...props }) => {
	const featuredPosts = props.featuredResources.slice(0, 1)
	const posts = props.featuredResources.slice(1, props.featuredResources.length)
	const usedIds = props.featuredResources.map(post => post.id)

	let recentPosts = useStaticQuery(graphql`
		query RecentPosts {
			allWpPost(
				limit: 14
				filter: {
					locale: {id: {eq: "en_US"}}, 
					resourceTypes: {nodes: {elemMatch: {slug: {ne: "events"}}}}
				}
				sort: {order: DESC, fields: date}
			) {
				edges {
					post: node {
						id
						uri
						title
						resourceTypes {
							nodes {
								name
								slug
							}
						}
						resourceInformation {
							service {
								... on WpService {
									title
									uri
									serviceTypes {
										nodes {
											slug
											name
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	recentPosts = recentPosts?.allWpPost?.edges
	// get rid of duplicates that will show up in the first five featured
	recentPosts = recentPosts.filter(item => !usedIds.includes(item.post.id))
	// we asked for 14 just in case the featured 5 were part of this set; not lets reduce
	recentPosts = recentPosts.slice(0, 9)

	return (
		<section className={featuredResources}>
			<Container fluid="lg">
				<Row>
					<Col xs={12} lg={7}>
						{featuredPosts.map(post => {
							let eventDetails = null
							if (post.resourceTypes?.nodes[0]?.slug === 'events') {
								eventDetails = parseEventDetails(post.eventInformation)
							}
							return (
								<StyledCard
									iconSize="medium"
									key={'post-' + post.id}
									service={post?.resourceInformation?.service}
									label={post.resourceTypes?.nodes[0]?.name}
									labelId={post.resourceTypes?.nodes[0]?.slug}
									href={post.uri}
									isFeatured={true}
									title={post.title}
									headingSize="title--lg"
									image={
										post.featuredImage?.node?.localFile?.childImageSharp
											?.gatsbyImageData
									}
									eventDetails={eventDetails}
									imageSize="large"
								/>
							)
						})}
					</Col>
					<Col xs={12} lg={5}>
						<Row>
							{posts.map((post, index) => {
								let eventDetails = null
								if (post.resourceTypes?.nodes[0]?.slug === 'events') {
									eventDetails = parseEventDetails(post.eventInformation)
								}
								return (
									<Col xs={6} lg={12} key={index} className={featuredResourcesSmall}>
										<StyledCard
											iconSize='small'
											variation="CardB"
											service={post?.resourceInformation?.service}
											label={post.resourceTypes?.nodes[0]?.name}
											labelId={post.resourceTypes?.nodes[0]?.slug}
											href={post.uri}
											title={post.title}
											image={
												post.featuredImage?.node?.localFile?.childImageSharp
													?.gatsbyImageData
											}
											eventDetails={eventDetails}
											imageSize="small"
										/>
									</Col>
								)
							})}
						</Row>
					</Col>
				</Row>
				{props.includeRecent && (
					<div className={recentPostsWrapper}>
						<h2 className="title--xl">Recent</h2>
						<Row>
							{recentPosts.map(({ post }, index) => {
								return (
									<Col sm={6} md={4} key={index}>
										<StyledCard
											variation="CardE"
											service={post?.resourceInformation?.service}
											label={post.resourceTypes?.nodes[0]?.name}
											labelId={post.resourceTypes?.nodes[0]?.slug}
											href={post.uri}
											showImage={false}
											title={post.title}
											iconSize='small'
										/>
									</Col>
								)
							})}
						</Row>
					</div>
				)}
			</Container>
		</section>
	)
}

const parseEventDetails = eventDetails => {
	let details
	const eventStartDate = eventDetails?.startDate ?  new EventDate(eventDetails?.startDate) : null
	const eventEndDate = eventDetails?.endDate
		? new EventDate(eventDetails.endDate)
		: null
	let eventDate = `${eventStartDate?.monthLong} ${eventStartDate?.date}`
	if (eventEndDate?.date) {
		eventDate += ` – ${
			eventEndDate?.monthLong !== eventStartDate?.monthLong
				? eventEndDate?.monthLong
				: ''
		} ${eventEndDate?.date}`
	}
	eventDate += `<br /> ${eventDetails?.time}`
	details = {
		date: (
			<div
				className="editor-section"
				dangerouslySetInnerHTML={{ __html: eventDate }}
			/>
		),
	}

	return details
}

export default FeaturedResources
