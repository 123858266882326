import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import GoogleMapReact from 'google-map-react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import BtnLink from '../../Common/StyledButton/BtnLink'

import {
	markerWrapper,
	offices,
	officeItem,
	content,
	mapWrapper,
	styledImage,
} from './OfficeList.module.scss'

const defaultProps = {
	center: {
		lat: 33.65,
		lng: -117.75,
	},
	zoom: 6,
}

const AnyReactComponent = ({ city, style }) => (
	<div className={markerWrapper}>
		<span className="visually-hidden marker" style={style}>{city + ` Marker`}</span>
	</div>
)

const GoogleMap = ({ ...props }) => (
	<Container>
		<Row>
			<Col xs={{ order: 'last', span: 12 }} lg={{ order: 'first', span: 5 }}>
				<div className={offices}>
					<div className="d-flex flex-column">
						{props.offices.map(v => (
							<div className={officeItem}>
								{v?.officeInformation?.proOfficeImage ? (
									<GatsbyImage
										image={
											v?.officeInformation?.proOfficeImage?.localFile
												?.childImageSharp?.gatsbyImageData
										}
										alt={v?.officeInformation?.proOfficeCity}
										className={styledImage}
									/>
								) : (
									<StaticImage
										src="../../../images/logo-placeholder.png"
										alt={v?.officeInformation?.proOfficeCity}
										aspectRatio={1}
										width={150}
										height={150}
										className={styledImage}
									/>
								)}

								<div className={content}>
									<h2 className="h3">{v?.title}</h2>
									<address>
										{v?.officeInformation?.proOfficeStreet}
										<br />
										{v?.officeInformation?.proOfficeCity},
										{' '}
										{v?.officeInformation?.proOfficeState}
										{' '}
										{v?.officeInformation?.proOfficeZipcode}
										<br />
									</address>
									<div>
										{v?.officeInformation?.proOfficePhone && (
											<>
												<span>P. {v?.officeInformation?.proOfficePhone}</span>
												<br />
											</>
										)}

										{v?.officeInformation?.proOfficeFax && (
											<span>F. {v?.officeInformation?.proOfficeFax}</span>
										)}
									</div>

									<div>
										{v?.officeInformation?.proOfficeDirectionsLink && (
											<BtnLink
												to={v?.officeInformation?.proOfficeDirectionsLink}
												cta="Get Directions"
											/>
										)}
										{v?.officeInformation?.proOfficePeopleLink && (
											<BtnLink
												to={v?.officeInformation?.proOfficePeopleLink}
												cta="View People"
											/>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</Col>
			<Col
				xs={{ order: 'first', span: 12 }}
				lg={{ order: 'last', span: 7 }}
				className="p-0"
			>
				<div className={mapWrapper}>
					<GoogleMapReact
						bootstrapURLKeys={{
							key: 'AIzaSyCmZ9AYyY-x-J2eHVOiaIUKtn-YVPuZMeE',
						}}
						defaultCenter={defaultProps.center}
						defaultZoom={defaultProps.zoom}
					>
						{props.offices.map((v, index) => {
							const hasLatLng =
								!!v?.officeInformation?.proOfficeLat &&
								!!v?.officeInformation?.proOfficeLng

							return hasLatLng ? (
								<AnyReactComponent
									key={`marker-${index}`}
									lat={v?.officeInformation?.proOfficeLat}
									lng={v?.officeInformation?.proOfficeLng}
									city={v?.officeInformation?.proOfficeCity}
									style={{color: "#000000"}}
								/>
							) : null
						})}
					</GoogleMapReact>
				</div>
			</Col>
		</Row>
	</Container>
)

export default GoogleMap
