import React, {useRef} from 'react'
import {navigate} from "gatsby";
import { Container, Row, Col, Form } from 'react-bootstrap'
import SearchInput from '../../SearchInput'
import BtnLink from '../../Common/StyledButton/BtnLink'
import Button from '../../Button'
import {
	search,
	searchPreHeader,
	searchHeader,
	searchContent,
	searchLinks,
	searchBtn,
} from './QuickSearch.module.scss'

const QuickSearch = ({
	headingText,
	introCopy,
	links,
	ctaButton,
	...props
}) => {
	const formRef = useRef(null)
	const handleSubmit = e => {
		e.preventDefault();
		const searchParams = new URLSearchParams(new FormData(formRef.current)).toString();
		navigate(`/people/?${searchParams}`)
	}

	return (
		<>
			<Container className={search}>
				{/* Heading and search */}
				<Row className="justify-content-md-center">
					<Col md={10} lg={7}>
						<div className={searchContent}>
							{headingText && (
								<h2 className={searchPreHeader}>{headingText}</h2>
							)}

							{introCopy && (
								<h1
									className={searchHeader}
									dangerouslySetInnerHTML={{ __html: introCopy }}
								/>
							)}

							<Form onSubmit={handleSubmit} ref={formRef}>
								<SearchInput iconSize="sm" onBtnClick={handleSubmit} />
							</Form>
							<hr />
						</div>
					</Col>
				</Row>

				{/* Links and CTA */}
				<Row className="justify-content-md-center">
					<Col md={7} lg={4}>
						<div className={searchLinks}>
							{links?.map((link, index) => (
								<BtnLink
									key={`quick-search-link-` + index}
									useButton={false}
									cta={link.text}
									href={link.href}
								/>
							))}
						</div>
					</Col>
					{ctaButton && (
						<Col md={3} lg={3}>
							<div className={searchBtn}>
								<Button
									buttonText={ctaButton.text}
									to={ctaButton.href}
									theme="gray"
									size="small"
								/>
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</>
	)
}

export default QuickSearch
