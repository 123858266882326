// extracted by mini-css-extract-plugin
export var accoladeGridWrapper = "AccoladeGrid-module--accoladeGridWrapper--129Oq";
export var headline = "AccoladeGrid-module--headline--2waJQ";
export var cta = "AccoladeGrid-module--cta--1xw1n";
export var title = "AccoladeGrid-module--title--3mjG1";
export var divider = "AccoladeGrid-module--divider--14YWz";
export var btnWrapper = "AccoladeGrid-module--btnWrapper--3H5Bx";
export var titleWrapper = "AccoladeGrid-module--titleWrapper--NlrjN";
export var gridItem = "AccoladeGrid-module--grid-item--3w2M5";
export var gridItemInner = "AccoladeGrid-module--grid-item-inner--2eGTE";
export var bgImage = "AccoladeGrid-module--bgImage--2SICy";
export var shadow = "AccoladeGrid-module--shadow--lk2v5";
export var accoladeCat = "AccoladeGrid-module--accoladeCat--3IOpH";