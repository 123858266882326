// extracted by mini-css-extract-plugin
export var alternatingContent = "AlternatingContent-module--alternatingContent--1ALK5";
export var noPaddingTop = "AlternatingContent-module--no-padding-top--3WTNN";
export var bg1 = "AlternatingContent-module--bg1--21TC1";
export var bg2 = "AlternatingContent-module--bg2--2pW5H";
export var media = "AlternatingContent-module--media--1kv6j";
export var bgCommunityService = "AlternatingContent-module--bgCommunityService--3TcKD";
export var headlineWrapper = "AlternatingContent-module--headlineWrapper--1CtWM";
export var accoladesTeaser = "AlternatingContent-module--accoladesTeaser--3Cp05";
export var content = "AlternatingContent-module--content--3PjY9";
export var contentWrapper = "AlternatingContent-module--contentWrapper--2JSEa";
export var textOnLeft = "AlternatingContent-module--textOnLeft--3QbJD";
export var cta = "AlternatingContent-module--cta--K71Xw";