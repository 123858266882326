// Customized from:
// https://github.com/ibrahima92/react-fullscreen-slider/blob/master/src/hooks/useSlider.js

import { useEffect } from 'react'

const useSlider = (slideText, slideAuthor, slideTitle, testimonialsList) => {
	let slideCounter = 0

	useEffect(() => startSlider())

	const startSlider = () => {
		slideText.current.innerHTML = testimonialsList[0].content
		slideAuthor.current.innerHTML =
			testimonialsList[0].testimonialInformation.author
		slideTitle.current.innerHTML =
			testimonialsList[0].testimonialInformation.jobtitle
	}

	const handleSlide = slide => {
		slideText.current.innerHTML = testimonialsList[slide - 1].content
		slideAuthor.current.innerHTML =
			testimonialsList[slide - 1].testimonialInformation.author
		slideTitle.current.innerHTML =
			testimonialsList[slide - 1].testimonialInformation.jobtitle
		animateSlide(slideText)
	}
	//TODO this animateSlide function is not working.
	// It was set to a background image in the starter code and set it to
	// slideText to test, but needs to be worked on to animate correctly.
	const animateSlide = () => {
		slideText.current.classList.add('fadeIn')
		setTimeout(() => {
			slideText.current.classList.remove('fadeIn')
		}, 1000)
	}

	const goToPreviousSlide = () => {
		if (slideCounter === 0) {
			handleSlide(testimonialsList.length)
			slideCounter = testimonialsList.length
		}

		handleSlide(slideCounter)
		slideCounter--
	}

	const goToNextSlide = () => {
		if (slideCounter === testimonialsList.length - 1) {
			startSlider()
			slideCounter = -1
			animateSlide(slideText)
		}

		slideText.current.innerHTML = testimonialsList[slideCounter + 1].content
		slideAuthor.current.innerHTML =
			testimonialsList[slideCounter + 1].testimonialInformation.author
		slideTitle.current.innerHTML =
			testimonialsList[slideCounter + 1].testimonialInformation.jobtitle

		slideCounter++
		animateSlide(slideText)
	}

	return { goToPreviousSlide, goToNextSlide }
}

export default useSlider
