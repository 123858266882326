import React, { useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import clsx from 'clsx'
import BtnLink from '../../Common/StyledButton/BtnLink'
import { useAccolades } from '../../../hooks/use-accolades'

import {
	cta,
	gridItem,
	gridItemInner,
	shadow,
	accoladeGridWrapper,
	accoladeCat,
	titleWrapper,
	divider,
	title,
	btnWrapper,
	bgImage,
  headline
} from './AccoladeGrid.module.scss'

const AccoladeGrid = ({ ...props }) => {
	
	const accoladesList = props.accoladeGroup ?? useAccolades()
	const filteredAccolades = accoladesList.reduce((acum, elem) => {
		const accolade = elem.hasOwnProperty('accolade') ? elem.accolade : elem
		if (!accolade.accoladeInformation.accoladetitle) {
			return acum
		} else {
			return [
				...acum,
				{
					date: accolade.accoladeInformation.date,
					title: accolade.accoladeInformation.accoladetitle,
					source: accolade.accoladeInformation.source,
					href: accolade.uri,
					id: accolade.id,
				},
			]
		}
	}, [])
	
	const startCount = 6;
	const [showCount, setShowCount] = useState(props.limit || startCount)
	const [accolades, setAccolades] = useState(filteredAccolades.slice(0, props.limit || startCount))
	const [loadMore, setLoadMore] = useState(true)
	const focusRef = useRef();
		
	const loadMoreAccolades = () => {
		if (props.limit) {
			return null
		}
		
		if (filteredAccolades.length > showCount) {
			setAccolades(filteredAccolades)
			setShowCount(filteredAccolades.length)
			setLoadMore(false)

			focusRef?.current?.focus()
		}	
	}

	return (
		<div className={accoladeGridWrapper}>
			{props.headline ? <h2 className={headline}>{props.headline}</h2> : null}

			<Container fluid="lg">
				<div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 gy-md-4 gx-md-4">
					{accolades.map((accoladeItem, index) => {
						return (
							<div
								key={`${accoladeItem.id}`}
								className={clsx(
									gridItem,
									'isotope-grid-item',
									'col',
								)}
							>
								<div className={shadow} />
								<div className={gridItemInner}>
									<div className={bgImage} />
									{accoladeItem.date ? (
										<div className="article-meta">{accoladeItem.date}</div>
									) : null}

									<div className={titleWrapper}>
										<span className={title}>{accoladeItem.title}</span>
										<br />
										{accoladeItem.source ? (
											<span className={`label-small ${accoladeCat}`}>
												{accoladeItem.source}
											</span>
										) : null}
									</div>

									<div className={cta}>
										<span className={divider}>
											<hr />
										</span>
										<BtnLink id={`accolade-${index}-link`} cta="Learn More" href={accoladeItem.href} ref={index === startCount - 1 ? focusRef : null} />
									</div>
								</div>
							</div>
						)
					})}
				</div>

				<Row>
					{loadMore && (
						<div className={btnWrapper}>
							<button
								type="button"
								onClick={loadMoreAccolades}
								className="btn btn-outline-secondary"
							>
								load more accolades
							</button>
						</div>
					)}
				</Row>
			</Container>
		</div>
	)
}

export default AccoladeGrid