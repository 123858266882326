import React from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import ResourcesSearchForm from '../../Resources/SearchForm'
import SubMenu from '../SubMenu'
import {
	resourcesWrapper,
	headingWrapper,
} from './ResourcesHeadingSearch.module.scss'

// Note: Separate mobile and desktop specific markup is needed to preserve logical tabbing order for keyboard users.
// Cannot use CSS order as it will not preserve tabbing order

const ResourcesHeadlineSearch = ({ headline, ...props }) => {
	return (
		<>
			{/* submenu on tablet+ */}
			<div className={`submenu-desktop d-none d-md-block`}>
				<SubMenu location={`resources`} />
			</div>

			<div className={headingWrapper}>
				<Container fluid="md">
					<Row>
						<Col md={4}>
							<h1>{headline}</h1>
						</Col>
						<Col>
							<ResourcesSearchForm />
						</Col>
					</Row>
				</Container>
			</div>

			{/* submenu on mobile */}
			<div className={`submenu-mobile d-md-none ${resourcesWrapper}`}>
				<SubMenu location={`resources`} />
			</div>
		</>
	)
}

export default ResourcesHeadlineSearch
