import React from 'react'
import clsx from "clsx";
import { Container } from 'react-bootstrap'
import { contactBlock, contactBlockNoPaddingTop } from './ContactForm.module.scss'
import ProcopioForm from "../../ProcopioForm";

const ContactForm = ({ ...props }) => {
	return (
		<div
			className={clsx(
				contactBlock,
				props.prevBlock?.endsWith('HeroHeadline') ? contactBlockNoPaddingTop : ''
			)}
		>
			<Container>
				<ProcopioForm formId={Number(props.gravityForm)} />
			</Container>
		</div>
	)
}

export default ContactForm
