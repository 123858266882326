import React from 'react'
import clsx from "clsx";
import {Container, Row, Col} from "react-bootstrap";
import StyledImage from "../../Common/StyledImage";
import Play from "../../Play";
import MediaModal from "../../MediaModal";
import {videoPlayButton} from "../../Resources/Detail/Video/Video.module.scss";
import {
  blockVideosGrid,
  blockVideosGridNoPaddingTop,
  blockVideosGridNoPaddingBottom,
  blockVideosGridItem,
  blockVideosGridItemHeading,
} from './VideosGrid.module.scss'
import getVimeoLink from "../../../helpers/getVimeoLink";

const VideoPlayButton = ({ title, image, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-label="Watch Video"
      className={videoPlayButton}
    >
      <StyledImage imageData={image} alt={title} />
      <Play size="small" bgColor="transparent" />
    </button>
  )
}

const VideosGrid = ({...props}) => {
  const { video, excludePaddingBottom, excludePaddingTop } = props
  return (
    <div className={clsx(
      blockVideosGrid,
      excludePaddingTop ? blockVideosGridNoPaddingTop : '',
      excludePaddingBottom ? blockVideosGridNoPaddingBottom : '',
      )}>
      <Container fluid="lg">
        <Row>
          {video.map((video, index) => {
            const VideoModal = MediaModal(VideoPlayButton, {
              title: video?.title,
              image: video?.image?.localFile?.childImageSharp?.gatsbyImageData,
            })
            const vimeoLink = getVimeoLink(video?.vimeoPlayer)
            return (
              <Col sm={6} lg={4} key={index}>
                <div className={blockVideosGridItem}>
                  <VideoModal
                    title={video?.title}
                    modalId={`video-modal-${index}`}
                    content={
                      <div className="ratio ratio-16x9">
                        <iframe
                          src={vimeoLink}
                          title={video?.title}
                          allowFullScreen
                        />
                      </div>
                    }
                  />
                  <h3 className={clsx('label-small', blockVideosGridItemHeading)}>{video?.title}</h3>
                  <div>
                    <p>
                      {video?.description}
                    </p>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default VideosGrid