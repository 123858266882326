import React, { useRef } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Link from '../../../Link'
import Button from '../../../Button'
import useSlider from '../../../../hooks/use-slider'

import {
	slider,
	sliderHeadline,
	btnCircle,
	featureBtn,
	quote,
	testimonialWrapper,
	authorWrapper,
} from './Slider.module.scss'

const Slider = ({ headline, ctaLink, ctaText, testimonialsList }) => {
	const slideText = useRef(null)
	const slideAuthor = useRef(null)
	const slideTitle = useRef(null)
	const { goToPreviousSlide, goToNextSlide } = useSlider(
		slideText,
		slideAuthor,
		slideTitle,
		testimonialsList
	)
	return (
		<>
			<Row>
				<Col xs={12}>
					<div className={[quote, 'd-block d-sm-none'].join(' ')}></div>
				</Col>

				<Col xs={12} sm={8} md={9} lg={{ span: 8, offset: 1 }}>
					<div className={slider}>
						<div className={sliderHeadline}>
							{headline ? <h2 className="title--lg">{headline}</h2> : null}
						</div>

						<div ref={slideText} className={testimonialWrapper}></div>

						<div className={authorWrapper}>
							<span
								className="title--sm text--dark-grey"
								ref={slideAuthor}
							></span>
							<br />
							<span className="label-small text--grey" ref={slideTitle}></span>
						</div>
					</div>
				</Col>
				<Col sm={4} md={3}>
					<div className={[quote, 'd-none d-sm-block'].join(' ')}></div>
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={4} md="auto" lg={{ offset: 1 }}>
					<button
						onClick={goToPreviousSlide}
						className={[
							btnCircle,
							'btn text-gray-dark btn-outline-secondary',
						].join(' ')}
					>
						<i className="ss-icon ss-black-tie-bold ss-left"></i>
					</button>
					<button
						onClick={goToNextSlide}
						className={[
							btnCircle,
							'btn text-gray-dark btn-outline-secondary',
						].join(' ')}
					>
						<i className="ss-icon ss-black-tie-bold ss-right"></i>
					</button>
				</Col>
				<Col xs={12} sm={8} md={8} xl={9}>
					<Button buttonText={ctaText} to={ctaLink} theme={'transparent'} />
				</Col>
			</Row>
		</>
	)
}

export default Slider
