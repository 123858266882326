import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ProAccordion from '../../Accordion'
import { accordionWrapper } from './GeneralAccordion.module.scss'

const GeneralAccordion = ({ ...props }) => {
	const { accordion } = props

	return (
		<>
			<Container>
				<Row className={`justify-content-md-center ` + accordionWrapper}>
					<Col md={props?.columnWidth ?? 10}>
						{accordion?.map((accord, index) => (
							<div key={index}>
								<ProAccordion
									title={accord.accordionTitle}
									content={accord.accordionContent}
									ctaText={accord.optionalCta.ctaText}
									ctaLink={accord.optionalCta.ctaLink}
									index={index + 1}
									key={index}
									first={index === 0}
								/>
							</div>
						))}
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default GeneralAccordion
