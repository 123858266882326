import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { generalContent } from './GeneralContent.module.scss'

const GeneralContent = ({ content, ...props }) => {
	return (
		<>
			<Container className={generalContent}>
				<Row className={`justify-content-md-center`}>
					<Col md={props?.columnWidth ?? 10}>
						<div
							className="general-content editor-section"
							dangerouslySetInnerHTML={{
								__html: content,
							}}
						/>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default GeneralContent
