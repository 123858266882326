import React from 'react';
import {Container } from 'react-bootstrap'
import Button from '../../Button'
import StyledCard from "../../Common/StyledCard"
import {useUpNextVideos} from "../../../hooks/use-up-next-videos";
import { blockRecommendedVideos } from './RecommendedVideos.module.scss'

const RecommendedVideos = ({heading, ctaButton, videos, ...props}) => {
  const upNextVideos = useUpNextVideos();
  // combine query and user selected
  let videoList = videos?.length ? [...videos, ...upNextVideos] : upNextVideos;
  // create uniform data
  videoList = videoList.map(post => {
    return post.hasOwnProperty('video') ? post.video : post;
  })

  // we combined the user selected vidoes and queried videos, lets de-dupe
  const seen = [];
  videoList = videoList.filter(function(item, pos) {
    if (!seen.includes(item.id)) {
      seen.push(item.id)
      return true
    } else {
      return false
    }
  })

  const featuredPosts = videoList.slice(0, 1);
  let posts = videoList.slice(1, 2);
  posts = posts.map(post => {
    return {
      id: post.id,
      href: post.uri,
      title: post.title,
      image: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
      time: post.videoInformation?.duration,
    }
  })

  return (
    <section className={blockRecommendedVideos}>
      <Container>
        <header className='d-flex justify-content-between align-items-center'>
          {heading &&
          <h2 className='title--xl title--decor'>
            {heading}
          </h2>
          }

          {ctaButton &&
          <Button
            buttonText={ctaButton.text}
            to={ctaButton.href}
            theme='transparent-white'
            className='d-none d-md-inline-block'
          />
          }

        </header>

        <div>
          {featuredPosts &&
          featuredPosts.map(post => {
              return (
								<StyledCard
									variation="VideoLarge"
									key={post.id}
									service={post?.resourceInformation?.service}
									href={post.uri}
									headingSize="title--lg"
									title={post.title}
									image={
										post.featuredImage?.node?.localFile?.childImageSharp
											?.gatsbyImageData
									}
									eventDetails={null}
									imageSize="xlarge"
									time={post.videoInformation?.duration}
									play={true}
									upNext={{
										title: 'Up Next',
										items: posts,
									}}
								/>
							)
            })
          }
        </div>

        <div className="text-center">
          <Button
            buttonText={ctaButton.text}
            to={ctaButton.href}
            theme='transparent-white'
            className='d-md-none'
          />
        </div>


      </Container>
    </section>
  )
}


export default RecommendedVideos