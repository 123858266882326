import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Link from '../Link'
import {
	sectorListWrapper,
	sectorList,
	sectorsWrapper,
	heading,
} from './BrowseSectors.module.scss'
import useSectorListQuery from '../../hooks/use-sector-list-query'

const SectorList = ({ sectors }) => {
	return (
		<>
			{sectors && (
				<ul className={sectorList}>
					{sectors.map((data, i) => (
						<li key={i}>
							<Link to={data?.sector?.uri}>{data?.sector?.title}</Link>
						</li>
					))}
				</ul>
			)}
		</>
	)
}

const SectorColumns = sectorData => {
	const numberOfColumn = 3
	let columnLength = Math.floor(sectorData.length / numberOfColumn)
	columnLength =
		sectorData.length % numberOfColumn != 0 ? columnLength + 1 : columnLength

	return (
		<Row>
			{Array.from(Array(numberOfColumn)).map((data, i) => {
				let from = i * columnLength
				let to = (i + 1) * columnLength

				return (
					<Col md={4} key={i}>
						<div className={sectorListWrapper}>
							<SectorList sectors={sectorData.slice(from, to)} />
						</div>
					</Col>
				)
			})}
		</Row>
	)
}

const BrowseSectors = ({ headingText, type }) => {
	const sectorData = useSectorListQuery(type)

	return (
		<>
			<section className={sectorsWrapper}>
				<Container fluid="lg">
					<Row>
						<Col md={{ span: 10, offset: 1 }}>
							{headingText && (
								<div className={heading}>
									<h2
										className="title--xl"
										dangerouslySetInnerHTML={{ __html: headingText }}
									/>
								</div>
							)}

							{sectorData ? SectorColumns(sectorData) : null}
						</Col>
					</Row>
				</Container>
			</section>
		</>
	)
}

export default BrowseSectors
