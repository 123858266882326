import React from 'react'
import {GatsbyImage} from 'gatsby-plugin-image'
import clsx from 'clsx'
import Link from '../../Link'
import {
	marquee,
	marqueeContent,
	marqueeItem,
} from './HeroSliderVertical.module.scss'

// the loop is repeated twice so we can create "clones" for seamless repetition during animation
const HeroSliderVertical = ({ images, link }) => {
	return (
		<div className={marquee}>
			<ul className={clsx(marqueeContent)}>
				{images.map((image, index) => (
					<li className={marqueeItem} key={`vertical-image-${index}`}>
						{link ? (
							<Link to={link}>
								<GatsbyImage alt={image.alt} image={image.image} className="rounded" loading="eager" />
							</Link>
						) : (
							<GatsbyImage alt={image.alt} image={image.image} className="rounded" loading="eager" />
						)}
					</li>
				))}
				{images.map((image, index) => (
					<li className={marqueeItem} key={`vertical-image-2-${index}`}>
						{link ? (
							<Link to={link}>
								<GatsbyImage alt={image.alt} image={image.image} className="rounded" loading="eager" />
							</Link>
						) : (
							<GatsbyImage alt={image.alt} image={image.image} className="rounded" loading="eager" />
						)}
					</li>
				))}
			</ul>
		</div>
	)
}

export default HeroSliderVertical
