import React from 'react'
import Link from '../../Link'
import BtnLink from '../../Common/StyledButton/BtnLink'
import { ctaIcon, textOnlyCta } from './HeroCta.module.scss'

const HeroCta = ({ variant, icon = '', text = '', href = '' }) => {
	const CtaVariant = variantType => {
		switch (variantType) {
			case 'icon':
				return (
					<Link to={href}>
						<span className={ctaIcon}>
							<i className="ss-icon ss-black-tie-bold">{icon || 'down'}</i>
						</span>
						<span className="article-meta">{text}</span>
					</Link>
				)
			case 'button':
				return (
					<Link to={href}>
						<span className="article-meta btn btn-primary">{text}</span>
					</Link>
				)
			case 'btn-link-primary':
				return <BtnLink cta={text} href={href} />
			case 'btn-link':
				return <BtnLink cta={text} href={href} variant="secondary" />
			case 'btn-link-white':
				return <BtnLink cta={text} href={href} variant="white" />
			case 'btn-link-white-primary':
				return <BtnLink cta={text} href={href} variant="white-primary" />
			case 'text-only':
				// This is a non-link, text only CTA (not interactive)
				return (
					<p className={textOnlyCta + ' article-meta'}><i className="ss-icon ss-black-tie-bold" aria-hidden="true">{icon || 'down'}</i> {text}</p>
				)
		}
	}

	return <>{variant ? CtaVariant(variant) : null}</>
}

export default HeroCta
