import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Button from '../../Button'
import EventCards from '../../../list/resources/Events/EventCards'
import {useUpcomingEvents} from "../../../hooks/use-upcoming-events";

import { blockUpcomingEvents } from './UpcomingEvents.module.scss'

const UpcomingEvents = ({ heading, ctaButton, resources, ...props }) => {
	const events = resources ?? useUpcomingEvents()

	return (
		<section className={blockUpcomingEvents}>
			<Container fluid="lg">
				<header className={`d-flex justify-content-between`}>
					{heading && <h2 className={`title--xl title--decor`}>{heading}</h2>}

					{ctaButton && (
						<Button
							buttonText={ctaButton.text}
							to={ctaButton.href}
							theme={'white'}
							className={`d-none d-md-inline-block`}
						/>
					)}
				</header>

				<div>
					{events.length ? (
						<Row>
							<EventCards
								events={events}
								title={null}
								label={'Events'}
								labelId={'events'}
							/>
						</Row>
					) : (
						<p>There are no upcoming events.</p>
					)}
				</div>

				{ctaButton && (
					<div className="text-center">
						<Button
							buttonText={ctaButton.text}
							to={ctaButton.href}
							theme={'white'}
							className={`d-md-none`}
						/>
					</div>
				)}
			</Container>
		</section>
	)
}

export default UpcomingEvents
